import React from "react";
import { Typography } from "@material-ui/core";
import clsx from "clsx";
import styles from "./styles.module.scss";

const Header = ({ title, children, containerStyles, variant = "h4" }) => (
  <div className={clsx(styles.container, containerStyles)}>
    <Typography className={styles.title} variant={variant}>
      {title}
    </Typography>
    {children}
  </div>
);

export default Header;
