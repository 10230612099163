import React, { useState } from "react";
import { Modal as MUModal } from "@material-ui/core";

import { getModalStyle } from "utils/functions";
import styles from "./styles.module.scss";

const Modal = ({ toggleModal, isOpen, children }) => {
  const [modalStyle] = useState(getModalStyle);
  return (
    <MUModal open={isOpen} onClose={toggleModal}>
      <div style={modalStyle} className={styles.container}>
        {children}
      </div>
    </MUModal>
  );
};

export default Modal;
