import React from "react";
import FilterListIcon from "@material-ui/icons/FilterList";

import Modal from "components/Modal";
import Button from "components/Button";

import styles from "./styles.module.scss";

const Filters = ({ isOpen, toggleModal, children, apply }) => (
  <>
    <div onClick={toggleModal}>
      <FilterListIcon className={styles.filterIcon} />
    </div>
    <Modal isOpen={isOpen} toggleModal={toggleModal}>
      {children}
      <div className={styles.buttonsContainer}>
        <Button
          iconType="cancel"
          color="dafault"
          title="Cancel"
          onClick={toggleModal}
        />
        <Button iconType="save" title="Save" onClick={apply} />
      </div>
    </Modal>
  </>
);

export default Filters;
