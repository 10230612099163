import React from "react";
import {
  Switch,
  Route,
  BrowserRouter as Router,
  Redirect,
} from "react-router-dom";
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";

import DemoSimulator from "./demo";
import PlatformSimulator from "./platform";
import Graphiql from "./graphiql";

const auth0Config = {
  domain: "modus-auth-07x8zjdolie.us.auth0.com",
  clientId: "T9aP7lJ2G5xUbwSdMZpURubCGX4sOZ2X",
  redirectUri: window.location.origin,
  audience: "https://simulator.modustools.com",
};

const Authorization = ({ children }) => {
  const [loading, setLoading] = React.useState(true);

  const {
    isLoading,
    isAuthenticated,
    loginWithRedirect,
    getAccessTokenSilently,
    error,
  } = useAuth0();

  React.useEffect(() => {
    if (isLoading) {
      return;
    }

    if (error) {
      setLoading(false);
      return;
    }

    if (!isAuthenticated) {
      loginWithRedirect();
    } else {
      getAccessTokenSilently().then((token) => {
        localStorage.setItem("token", token);
        setLoading(false);
      });
    }
  }, [error, isAuthenticated, isLoading]);

  if (isLoading || loading) {
    return <div style={{ color: "white" }}>Loading...</div>;
  }

  if (error) {
    return <div style={{ color: "white" }}>Error: {error.toString()}</div>;
  }

  return <>{children}</>;
};

const MainPage = () => (
  <Router>
    <Switch>
      <Route path="/demo">
        <DemoSimulator />
      </Route>
      <Auth0Provider {...auth0Config}>
        <Authorization>
          <Route path="/platform">
            <PlatformSimulator />
          </Route>
          <Route path="/graphiql">
            <Graphiql />
          </Route>
          <Route exact path="/">
            <Redirect to="/platform/tasks" />
          </Route>
        </Authorization>
      </Auth0Provider>
    </Switch>
  </Router>
);

export default MainPage;
