import { ENVIRONMENTS } from "consts";

export const ENVIRONMENTS_OPTIONS = [
  { value: "ALL", text: "All" },
  ...ENVIRONMENTS,
];

export const HEADER_ITEMS = ["Task ID", "Name", "Description", "Status", "Env"];

export const FIELDS_ITEMS = ["id", "name", "description", "status", "env"];
