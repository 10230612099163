/* eslint-disable */
import { useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";

export const getPaginatedList = ({
  setPagination,
  setData,
  search,
  currentPagination,
  query,
  type,
  filters,
  fetchPolicy = "cache-and-network",
}) => {
  const { data, loading, error } = useQuery(query, {
    variables: {
      filter: filters || {},
      search,
      pagination: {
        page: currentPagination.page,
        perPage: currentPagination.perPage,
      },
    },
    fetchPolicy,
  });

  useEffect(() => {
    if (loading === false && data) {
      const { perPage, totalEntries } = data && data[type].pagination;
      const { entities } = data && data[type];
      setPagination(
        (pagination) => (pagination = { ...pagination, perPage, totalEntries })
      );
      setData(entities);
    }
  }, [data, loading, setPagination, type, setData]);

  return { data, loading, error };
};
