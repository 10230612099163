import React from "react";
import { Grid } from "@material-ui/core";

import TextField from "components/TextField";
import Button from "components/Button";
import Modal from "components/Modal";
import TripsEdit from "routes/platform/Tasks/Trips";

import { REGEXP, STEPS_TYPES, MESSAGES } from "consts";

import styles from "./styles.module.scss";

const AttrubutesPopup = ({
  isModalShown,
  toggleModal,
  handleSubmit,
  submitAttributes,
  modalType,
  errors,
  register,
  closeModal,
}) => (
  <Modal isOpen={isModalShown} toggleModal={toggleModal}>
    <form onSubmit={handleSubmit(submitAttributes)}>
      <div className={styles.modalContainer}>
        {modalType === STEPS_TYPES.DELAY && (
          <div className={styles.textFieldWrapper}>
            <TextField
              id="Delay"
              fullWidth
              type="number"
              isError={!!errors.delay}
              errorMessage={MESSAGES.INCORRECT_VALUE}
              register={register({
                required: true,
                pattern: REGEXP.POSITIVE_INTEGER_NUMBERS_WITHOUT_0,
              })}
              name="delay"
              label="Delay"
              errors={errors}
              placeholder="Insert Delay"
            />
          </div>
        )}
        {modalType === STEPS_TYPES.RANDOM_DELAY && (
          <>
            <div className={styles.textFieldWrapper}>
              <TextField
                id="Min Delay"
                fullWidth
                type="number"
                isError={!!errors.minDelay}
                errorMessage={MESSAGES.INCORRECT_VALUE}
                register={register({
                  required: true,
                  pattern: REGEXP.POSITIVE_INTEGER_NUMBERS_WITHOUT_0,
                })}
                name="minDelay"
                label="Min Delay"
                errors={errors}
                placeholder="Insert Min Delay"
              />
            </div>
            <div className={styles.textFieldWrapper}>
              <TextField
                id="Max Delay"
                fullWidth
                type="number"
                isError={!!errors.maxDelay}
                errorMessage={MESSAGES.INCORRECT_VALUE}
                register={register({
                  required: true,
                  pattern: REGEXP.POSITIVE_INTEGER_NUMBERS_WITHOUT_0,
                })}
                name="maxDelay"
                label="Max Delay"
                errors={errors}
                placeholder="Insert Max Delay"
              />
            </div>
          </>
        )}
        {modalType === STEPS_TYPES.LOOP && (
          <div className={styles.textFieldWrapper}>
            <TextField
              fullWidth
              type="number"
              isError={!!errors.iterationsCount}
              errorMessage={MESSAGES.INCORRECT_VALUE}
              register={register({
                required: true,
                pattern: REGEXP.POSITIVE_INTEGER_NUMBERS_WITHOUT_0,
              })}
              name="iterationsCount"
              label="Iterations Count"
              errors={errors}
              placeholder="Insert Count"
            />
          </div>
        )}
        {modalType === STEPS_TYPES.TRIP && (
          <TripsEdit closeModal={closeModal} />
        )}
      </div>
      {modalType !== STEPS_TYPES.TRIP && (
        <Grid className={styles.buttonsContainer} item xs={12}>
          <Button
            iconType="cancel"
            onClick={closeModal}
            color="default"
            title="Cancel"
          />
          <Button
            iconType="save"
            title="Save"
            type="submit"
            isDisabled={
              !!errors.iterationsCount ||
              !!errors.maxDelay ||
              !!errors.minDelay ||
              !!errors.delay
            }
          />
        </Grid>
      )}
    </form>
  </Modal>
);

export default AttrubutesPopup;
