import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import { useRouteMatch, useHistory } from "react-router-dom";
import { useMutation } from "@apollo/react-hooks";
import { useForm } from "react-hook-form";

import Select from "components/Select";
import Header from "components/Header";
import Button from "components/Button";
import TextField from "components/TextField";
import { REGEXP, ENVIRONMENTS, MESSAGES } from "consts";
import { createTask as createTaskMutation } from "api";

import styles from "./styles.module.scss";

const TaskNew = () => {
  const { register, handleSubmit, errors } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });
  const [env, setEnv] = useState("");
  const { url } = useRouteMatch();
  const history = useHistory();

  const onCompleted = ({ createTask }) =>
    history.push(`${url}/${createTask.id}/edit`);

  const [createTaskHook] = useMutation(createTaskMutation, {
    onCompleted,
  });

  const changeEnv = (event) => setEnv(event.target.value);

  const onSubmit = ({ name, description }) => {
    createTaskHook({ variables: { env, name, description } });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Header title="New Task" />
        </Grid>
        <Grid item xs={4}>
          <TextField
            isError={!!errors.name}
            errorMessage={MESSAGES.INCORRECT_VALUE}
            register={register({ required: true, pattern: REGEXP.TEXTFIELD })}
            name="name"
            fullWidth
            label="Name"
            errors={errors}
            placeholder="Insert Title"
          />
        </Grid>
        <Grid item xs={8}>
          <Select
            placeholder="Select Environment"
            selectedValue={env}
            onChange={changeEnv}
            values={ENVIRONMENTS}
            fullWidth
            required
            containerClassName={styles.selectContainer}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            isError={!!errors.description}
            errorMessage={MESSAGES.INCORRECT_VALUE}
            register={register({
              required: true,
              pattern: REGEXP.RICH_TEXTFIELD,
            })}
            name="description"
            fullWidth
            label="Description"
            errors={errors}
            placeholder="Insert Description"
          />
        </Grid>
        <Grid item xs={10}></Grid>
        <Grid item xs={2}>
          <Button
            iconType="save"
            title="Save"
            type="submit"
            isDisabled={!!errors.name || !!errors.description || !env}
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default TaskNew;
