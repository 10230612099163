import React from "react";
import { Grid } from "@material-ui/core";
import { withRouter, Switch, Route } from "react-router-dom";
import { flowRight } from "lodash";

import TripList from "routes/platform/Trips/TripsList";
import ImportTrips from "routes/platform/Trips/ImportTrips";

function TripPage({ match }) {
  return (
    <Grid container spacing={3}>
      <Switch>
        <Route path={`${match.url}/import`}>
          <ImportTrips />
        </Route>
        <Route path={`${match.url}/:tripID`}></Route>
        <Route path={`${match.url}`}>
          <TripList />
        </Route>
      </Switch>
    </Grid>
  );
}

export default flowRight([withRouter])(TripPage);
