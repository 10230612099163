import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";

import { useAuth0 } from "@auth0/auth0-react";

import { getTasks } from "api";
import TaskNew from "routes/platform/Tasks/TaskNew";
import Header from "components/Header";
import Button from "components/Button";
import Search from "components/Search";
import Modal from "components/Modal";
import Error from "components/Error";
import SimpleTable from "components/SimpleTable";
import Filters from "components/Filters";
import Select from "components/Select";
import MultipleSelect from "components/MultipleSelect";

import { getPaginatedList } from "hooks/getPaginatedList";

import { deletePropsFromObject } from "utils/functions";
import { TASK_STATUSES_ARRAY } from "consts";
import { HEADER_ITEMS, FIELDS_ITEMS, ENVIRONMENTS_OPTIONS } from "./constants";

import styles from "./styles.module.scss";

const TasksList = () => {
  const [search, setSearch] = useState(null);
  const [tasks, setTasks] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({
    env: "ALL",
    status: [],
  });
  const [isFiltersOpen, toggleFiltersModal] = useState(false);
  const [filters, setFilters] = useState({});
  const [pagination, setPagination] = useState({
    page: 1,
    perPage: 20,
    totalEntries: 0,
  });
  const history = useHistory();

  const { loading, error } = getPaginatedList({
    setPagination,
    setData: setTasks,
    search,
    currentPagination: pagination,
    query: getTasks,
    type: "tasks",
    filters,
  });

  const changeStatus = (event) => {
    const { value } = event.target;
    setSelectedFilters({ ...selectedFilters, status: value });
  };

  const changeEnv = (event) => {
    const { value } = event.target;
    setSelectedFilters({ ...selectedFilters, env: value });
  };

  const applyFilters = () => {
    const formattedFilters = deletePropsFromObject(["ALL"], selectedFilters);
    if (!selectedFilters?.status?.length) delete formattedFilters.status;
    Object.keys(formattedFilters).length
      ? setFilters(formattedFilters)
      : setFilters({});
    toggleFiltersModal(false);
  };

  const handleChangePage = (_event, page) =>
    setPagination({ ...pagination, page: page + 1 });

  const changeSearch = (event) => setSearch(event.target.value);

  const selectTask = (deviceID) =>
    history.push(`${history.location.pathname}/${deviceID}`);

  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  const handleFiltersModal = () => toggleFiltersModal(!isFiltersOpen);

  if (error) return <Error message={error.message} />;

  return (
    <>
      <Grid item xs={12}>
        <Header title="Tasks">
          <Button iconType="add" onClick={handleOpen} title="Create" />
        </Header>
      </Grid>
      <Grid item xs={11}>
        <Search
          size="small"
          placeholder="Task Name"
          variant="outlined"
          type="search"
          value={search}
          onChange={changeSearch}
          fullWidth
        />
      </Grid>
      <Grid item className={styles.filterIconContainer} xs={1}>
        <Filters
          isOpen={isFiltersOpen}
          toggleModal={handleFiltersModal}
          apply={applyFilters}
        >
          <Select
            selectedValue={selectedFilters.env}
            onChange={changeEnv}
            label="Environments"
            values={ENVIRONMENTS_OPTIONS}
            fullWidth
            required
          />
          <MultipleSelect
            values={selectedFilters.status}
            label="Select status"
            data={TASK_STATUSES_ARRAY}
            handleChange={changeStatus}
          />
        </Filters>
      </Grid>
      <Grid item xs={12}>
        <SimpleTable
          header={HEADER_ITEMS}
          itemsFields={FIELDS_ITEMS}
          items={tasks}
          selectItem={selectTask}
          loading={loading}
          showPagination
          totalEntries={pagination.totalEntries}
          perPage={pagination.perPage}
          page={pagination.page}
          handleChangePage={handleChangePage}
        />
      </Grid>
      <Modal isOpen={open} toggleModal={handleClose}>
        <TaskNew />
      </Modal>
    </>
  );
};

export default TasksList;
