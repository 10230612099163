import React from "react";
import { get } from "lodash";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import DoneIcon from "@material-ui/icons/Done";
import RemoveIcon from "@material-ui/icons/Remove";
import {
  Paper,
  TablePagination,
  useTheme,
  Box,
  IconButton,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
} from "@material-ui/core";

import Loader from "components/Loader";
import NoData from "components/NoData";

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }} style={{ display: "flex" }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

const renderHeader = (header, isChecked) => (
  <TableHead>
    <TableRow>
      {isChecked && <TableCell />}
      {header.map((item) => (
        <TableCell size="medium" key={item}>
          {item}
        </TableCell>
      ))}
    </TableRow>
  </TableHead>
);

const renderTableBody = (
  items,
  selectItem,
  itemsFields,
  toggleSelectedIds,
  selectedIds,
  isChecked,
  children
) => (
  <TableBody>
    {items.map((item) => {
      const isSelected = selectedIds?.includes(item.id);
      return (
        <TableRow
          key={item.id}
          hover
          onClick={() =>
            (toggleSelectedIds && toggleSelectedIds(item.id)) ||
            (selectItem && selectItem(item.id))
          }
          role="checkbox"
          aria-checked={isSelected && isSelected}
          tabIndex={-1}
          selected={isSelected && isSelected}
        >
          {isChecked && (
            <TableCell padding="checkbox">
              <Checkbox checked={isSelected} />
            </TableCell>
          )}
          {itemsFields.map((field) => {
            if (typeof item[field] === "boolean") {
              return item[field] ? (
                <TableCell key={field}>
                  <DoneIcon />
                </TableCell>
              ) : (
                <TableCell key={field}>
                  <RemoveIcon />
                </TableCell>
              );
            } else if (typeof field === "function") {
              return <TableCell key={field}>{field(item)}</TableCell>;
            } else {
              return (
                <TableCell key={field}>{get(item, field) || "-"}</TableCell>
              );
            }
          })}
          {children && <TableCell>{children}</TableCell>}
        </TableRow>
      );
    })}
  </TableBody>
);

const SimpleTable = ({
  header,
  loading,
  items,
  selectItem,
  itemsFields,
  selectedIds,
  toggleSelectedIds,
  isChecked,
  children,
  showPagination,
  totalEntries,
  perPage,
  page,
  handleChangePage,
}) => {
  if (loading) {
    return <Loader />;
  }

  return (
    <Paper>
      {items?.length > 0 ? (
        <>
          <Table stickyHeader aria-label="sticky table" size="small">
            {renderHeader(header, isChecked)}
            {renderTableBody(
              items,
              selectItem,
              itemsFields,
              toggleSelectedIds,
              selectedIds,
              isChecked,
              children
            )}
          </Table>
          {showPagination && (
            <TablePagination
              rowsPerPageOptions={[]}
              component="div"
              count={totalEntries}
              rowsPerPage={perPage}
              page={page - 1}
              onChangePage={handleChangePage}
              ActionsComponent={TablePaginationActions}
            />
          )}
        </>
      ) : (
        <NoData text="No Data" />
      )}
    </Paper>
  );
};
export default SimpleTable;
