import gql from "graphql-tag";

import { pagination } from "api/props/pagination";

export const getDevices = gql`
  query Devices($search: String $pagination: PaginationParams) {
    devices(search_value: $search pagination: $pagination) {
      entities {
        id
        name
        description
        client {
          id
          name
        }
      }
      pagination{
        ${pagination}
      }
    }
  }
`;

export const getDevicesIds = gql`
  query Devices($search: String $pagination: PaginationParams $availableForTask: ID) {
    devices(search_value: $search pagination: $pagination filter: {availableForTask: $availableForTask}) {
      entities {
        id
        name
      }
      pagination {
        ${pagination}
      }
    }
  }
`;

export const deviceItem = gql`
  query DeviceItem($deviceID: ID!) {
    device(id: $deviceID) {
      id
      client {
        id
        name
      }
      createdAt
      description
      name
    }
  }
`;

export const TripDevices = gql`
  query task($tripID: UUID!) {
    trip(id: $tripID) {
      id
      originDevicesTrips {
        id
        originDevice {
          provider
          id
        }
      }
    }
  }
`;
