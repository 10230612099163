import React from "react";
import {
  MenuItem,
  FormControl,
  Select as MUSelect,
  Typography,
} from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import clsx from "clsx";

import styles from "./styles.module.scss";

const Select = ({
  selectedValue,
  onChange,
  label,
  values,
  variant = "filled",
  fullWidth,
  size = "small",
  required,
  name,
  placeholder,
  containerClassName,
}) => (
  <div className={clsx(styles.container, containerClassName)}>
    <Typography className={styles.label} gutterBottom>
      {label}
    </Typography>
    <FormControl variant={variant} fullWidth={fullWidth} size={size}>
      <InputLabel id="demo-simple-select-label">{placeholder}</InputLabel>
      <MUSelect
        name={name}
        labelId="demo-simple-select-required-label"
        id="demo-simple-select-required"
        value={selectedValue}
        onChange={onChange}
        required={required}
      >
        {values.map((item) => (
          <MenuItem key={item.value} value={item.value}>
            {item.text}
          </MenuItem>
        ))}
      </MUSelect>
    </FormControl>
  </div>
);
export default Select;
