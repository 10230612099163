import * as React from "react";
import { getDevices } from "api";
import { useForm, useMutation, useQuery, useSnackbar } from "hooks";
import { Grid } from "@material-ui/core";
import { Button, Modal, Header, TextField, Select } from "components";
import { MESSAGES } from "consts";
import { queries } from "./duck";
import styles from "./CreateDevice.module.scss";

const CreateDevice = ({ refetchVariables }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [opened, setOpened] = React.useState(false);

  const [createDevice, { loading }] = useMutation(queries.createDevice);

  const { register, handleSubmit, errors, setValue, watch } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: { name: "", description: "", clientId: null },
  });

  const clientId = watch("clientId");

  const { data } = useQuery(queries.getClients);
  const options = data ? data.clients.entities : [];

  React.useEffect(() => {
    register("clientId");
    // eslint-disable-next-line
  }, []);

  const onSubmit = (values) => {
    createDevice({
      variables: values,
      refetchQueries: [{ variables: refetchVariables, query: getDevices }],
    })
      .then(() => {
        setOpened(false);
        enqueueSnackbar("Device created successfully!", { variant: "success" });
      })
      .catch(() => {
        enqueueSnackbar("Failed to create device. Check network tab.", {
          variant: "error",
        });
      });
  };

  return (
    <>
      <Button iconType="add" onClick={() => setOpened(true)} title="Create" />
      <Modal isOpen={opened} toggleModal={() => setOpened(false)}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Header title="Create Device" />
            </Grid>
            <Grid item xs={4}>
              <TextField
                name="name"
                label="Device Name"
                placeholder="Device Name"
                register={register({ required: true })}
                isError={!!errors.name}
                errors={errors}
                errorMessage={MESSAGES.REQUIRED}
              />
            </Grid>
            <Grid item xs={8}>
              <Select
                label="Client"
                selectedValue={clientId}
                onChange={(event) => {
                  setValue("clientId", event.target.value);
                }}
                values={options
                  .map((opt) => ({ text: opt.name, value: opt.id }))
                  .filter((opt) => opt.text !== "unknown")}
                fullWidth
                placeholder="Select Client"
                required
              />
              <span className={styles.helpText}>
                Client where you plan to use this device.
              </span>
            </Grid>
            <Grid item xs={12}>
              <TextField
                isError={!!errors.description}
                errorMessage={MESSAGES.INCORRECT_VALUE}
                register={register()}
                name="description"
                fullWidth
                label="Description"
                errors={errors}
                placeholder="Insert Description"
              />
            </Grid>
            <Grid item xs={10} />
            <Grid item xs={2}>
              <Button
                loading={loading}
                title="Save"
                type="submit"
                iconType="save"
              />
            </Grid>
          </Grid>
        </form>
      </Modal>
    </>
  );
};

export default CreateDevice;
