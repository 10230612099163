import React, { useState, useEffect } from "react";
import { Paper, Button as MUButton, ButtonGroup } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { withApollo } from "react-apollo";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { useForm } from "react-hook-form";

import {
  createDelayStep,
  createLoopStep,
  createRandomDelayStep,
  taskItem,
  deleteStep as deleteStepMutation,
  updateTask as updateTaskMutation,
} from "api";
import Header from "components/Header";
import Loader from "components/Loader";
import TextField from "components/TextField";
import Button from "components/Button";
import StepsList from "routes/platform/Tasks/StepsList";
import AddStepPopup from "routes/platform/Tasks/AddStepPopup";
import {
  REGEXP,
  TASKS_ACTIONS,
  NOTIFICATION_TYPES,
  ROUTES,
  MESSAGES,
} from "consts";
import { secToMillisec, convertStepsTimeToSeconds } from "utils/functions";

import styles from "./styles.module.scss";

const refetchData = (taskID) => [
  {
    query: taskItem,
    variables: { taskID },
  },
];

const TaskEdit = ({ client }) => {
  const [task, setTask] = useState({});
  const [steps, setSteps] = useState([]);
  const { register, handleSubmit, errors, reset } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });
  const [isModalShown, toggleModal] = useState(false);
  const [modalType, setModalType] = useState("");
  const [isDeleteLoading, setDeleteLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { params } = useRouteMatch();

  const { data, loading } = useQuery(taskItem, {
    variables: { taskID: params.taskID },
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    reset({
      name: data?.task?.name,
      description: data?.task?.description,
    });
    setTask(data?.task);
    const formattedSteps = convertStepsTimeToSeconds(data?.task.steps);
    setSteps(formattedSteps);
  }, [loading, data, reset]);

  const taskUpdated = ({ updateTask }) =>
    history.push(`/${ROUTES.TASKS}/${updateTask.id}`);

  const [updateTask, updateTaskStatus] = useMutation(updateTaskMutation, {
    onCompleted: taskUpdated,
  });

  const stepCreated = ({
    createDelayStep,
    createLoopStep,
    createRandomDelayStep,
  }) => {
    if (createDelayStep) {
      setTask({ ...task, steps: [...task.steps, createDelayStep] });
    }
    if (createLoopStep) {
      setTask({ ...task, steps: [...task.steps, createLoopStep] });
    }
    if (createRandomDelayStep) {
      setTask({ ...task, steps: [...task.steps, createRandomDelayStep] });
    }
  };

  const [addDelayStep, addDelayStepStatus] = useMutation(createDelayStep, {
    onCompleted: stepCreated,
    refetchQueries: refetchData(params.taskID),
  });

  const [addRandomDelayStep, addRandomDelayStepStatus] = useMutation(
    createRandomDelayStep,
    {
      onCompleted: stepCreated,
      refetchQueries: refetchData(params.taskID),
    }
  );

  const [addLoopStep, addLoopStepStatus] = useMutation(createLoopStep, {
    onCompleted: stepCreated,
    refetchQueries: refetchData(params.taskID),
  });

  const deleteStep = (id) => {
    setDeleteLoading(true);
    client
      .mutate({
        mutation: deleteStepMutation,
        variables: { id },
        refetchQueries: refetchData(params.taskID),
      })
      .then(() => {
        const { steps } = task;
        setTask({ ...task, steps: steps.filter((step) => id !== step.id) });
        setDeleteLoading(false);
      })
      .catch((error) => {
        setDeleteLoading(false);
        enqueueSnackbar(error.message, { variant: NOTIFICATION_TYPES.ERROR });
      });
  };

  const submitAttributes = (data) => {
    const taskId = params.taskID;
    let { delay, maxDelay, minDelay, iterationsCount } = data;
    if (delay) {
      delay = secToMillisec(+delay);
      addDelayStep({
        variables: { delay: delay, taskId },
      });
    }
    if (maxDelay && minDelay) {
      if (+minDelay >= +maxDelay)
        return enqueueSnackbar(MESSAGES.MIN_GREATER_THAN_MAX, {
          variant: NOTIFICATION_TYPES.ERROR,
        });
      maxDelay = secToMillisec(+maxDelay);
      minDelay = secToMillisec(+minDelay);
      addRandomDelayStep({
        variables: {
          max: maxDelay,
          min: minDelay,
          taskId,
        },
      });
    }
    if (iterationsCount) {
      if (+iterationsCount > 30) {
        return enqueueSnackbar(MESSAGES.MAX_ITERATION_VALUE, {
          variant: NOTIFICATION_TYPES.ERROR,
        });
      }
      addLoopStep({
        variables: { count: +iterationsCount, taskId },
      });
    }
    closeModal();
  };

  const goBack = () => history.push(`/${ROUTES.TASKS}/${params.taskID}`);

  const onSubmit = ({ name, description }) =>
    updateTask({
      variables: {
        id: task.id,
        name: name,
        description: description,
      },
    });

  const addAction = (action) => {
    setModalType(action);
    toggleModal(true);
  };

  const closeModal = () => {
    setModalType("");
    toggleModal(false);
  };

  if (loading || !task || updateTaskStatus.loading) {
    return <Loader />;
  }

  return (
    <>
      <Grid
        component="form"
        container
        spacing={3}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid item xs={12}>
          <Header title="Edit Task">
            <ButtonGroup
              variant="contained"
              color="primary"
              aria-label="contained primary button group"
            >
              {TASKS_ACTIONS.map((action) => (
                <MUButton key={action} onClick={() => addAction(action)}>
                  {action}
                </MUButton>
              ))}
            </ButtonGroup>
          </Header>
        </Grid>
        <Grid item className={styles.itemsContainer} xs={12}>
          <TextField
            fullWidth
            isError={!!errors.name}
            errorMessage={MESSAGES.INCORRECT_VALUE}
            register={register({ required: true, pattern: REGEXP.TEXTFIELD })}
            name="name"
            label="Name"
            errors={errors}
          />
          <TextField
            multiline
            fullWidth
            isError={!!errors.description}
            errorMessage={MESSAGES.INCORRECT_VALUE}
            register={register({
              required: true,
              pattern: REGEXP.RICH_TEXTFIELD,
            })}
            name="description"
            label="Description"
            errors={errors}
          />
        </Grid>
        <Grid item xs={12}>
          <Paper>
            <StepsList
              data={steps}
              deleteItem={deleteStep}
              allowDelete
              loading={
                addDelayStepStatus.loading ||
                addRandomDelayStepStatus.loading ||
                addLoopStepStatus.loading ||
                isDeleteLoading
              }
            />
          </Paper>
        </Grid>
        <Grid className={styles.buttonsContainer} item xs={12}>
          <Button
            iconType="back"
            onClick={goBack}
            color="default"
            title="Back"
          />
          <Button
            iconType="save"
            title="Save"
            type="submit"
            isDisabled={!!errors.name || !!errors.description}
          />
        </Grid>
      </Grid>
      <AddStepPopup
        isModalShown={isModalShown}
        toggleModal={toggleModal}
        handleSubmit={handleSubmit}
        submitAttributes={submitAttributes}
        modalType={modalType}
        errors={errors}
        register={register}
        closeModal={closeModal}
      />
    </>
  );
};

export default withApollo(TaskEdit);
