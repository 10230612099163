import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { AppBar, Toolbar, IconButton, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import DriveEtaRoundedIcon from "@material-ui/icons/DriveEtaRounded";
import Button from "components/Button";
import { withRouter, NavLink } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: "24px",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    marginRight: 15,
    textDecoration: "none",
    color: theme.palette.text.primary,
  },
  cardRoot: {
    maxWidth: "100%",
  },
  cardMedia: {
    height: "100%",
  },
}));

const Menu = ({ match }) => {
  const classes = useStyles();

  const { logout } = useAuth0();

  return (
    <AppBar className={classes.container} position="static">
      <Toolbar>
        <IconButton
          edge="start"
          className={classes.menuButton}
          color="inherit"
          aria-label="menu"
          component={NavLink}
          to="/"
        >
          <DriveEtaRoundedIcon />
        </IconButton>
        <Typography
          variant="h6"
          className={classes.title}
          component={NavLink}
          to={`${match.url}/tasks`}
        >
          Tasks
        </Typography>
        <Typography
          variant="h6"
          className={classes.title}
          component={NavLink}
          to={`${match.url}/devices`}
        >
          Devices
        </Typography>
        <Typography
          variant="h6"
          className={classes.title}
          component={NavLink}
          to={`${match.url}/trips`}
        >
          Trips
        </Typography>

        <Button
          style={{ marginLeft: "auto" }}
          color="secondary"
          variant="outlined"
          title="Logout"
          onClick={() => {
            localStorage.clear();
            logout({ returnTo: window.location.origin });
          }}
        />
      </Toolbar>
    </AppBar>
  );
};

export default withRouter(Menu);
