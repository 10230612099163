import React from "react";
import { Grid, Typography } from "@material-ui/core";
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import { withRouter } from "react-router-dom";
import { flowRight } from "lodash";

import Loader from "components/Loader";

const deviceItemQuery = gql`
  query DeviceItem($deviceID: ID!) {
    device(id: $deviceID) {
      id
      clientId
      createdAt
      delayBetweenTrips
      description
      driverUuid
      loopTrips
      name
      status
    }
  }
`;

function DeviceItem({ data }) {
  const valueOrDash = (value) => value || "—";
  if (data.loading) {
    return <Loader />;
  }
  return (
    <Grid container spacing={3}>
      <Grid item xs={8}>
        <Typography variant="h3">{data.device.id}</Typography>
      </Grid>
      <Grid item xs={12}>
        status: {data.device.status || "-"}
      </Grid>
      <Grid item xs={12}>
        name: {valueOrDash(data.device.name)}
      </Grid>
      <Grid item xs={12}>
        description: {valueOrDash(data.device.description)}
      </Grid>
      <Grid item xs={12}>
        driverUuid: {valueOrDash(data.device.driverUuid)}
      </Grid>
      <Grid item xs={12}>
        loopTrips: {valueOrDash(data.device.loopTrips)}
      </Grid>
    </Grid>
  );
}

export default flowRight([
  withRouter,
  graphql(deviceItemQuery, {
    options: ({ match }) => ({
      variables: { deviceID: match.params.deviceID },
    }),
  }),
])(DeviceItem);
