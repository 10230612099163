import { ENVIRONMENTS } from "consts";

export const ENVIRONMENTS_OPTIONS = [
  { value: "ALL", text: "All" },
  ...ENVIRONMENTS,
];

export const HEADER_ITEMS = [
  "Trip ID",
  "Status",
  "Start Time",
  "End Time",
  "Distance",
  "Duration",
];

export const FIELDS_ITEMS = [
  "id",
  "status",
  "startTime",
  "endTime",
  "distance",
  "duration",
];
