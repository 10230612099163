/* eslint-disable */
import React from "react";
import {
  Container,
  Grid,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
} from "@material-ui/core";
import { withApollo } from "react-apollo";
import { makeStyles } from "@material-ui/core/styles";
import {
  NavLink,
  BrowserRouter as Switch,
  Route,
  useRouteMatch,
} from "react-router-dom";
import DriveEtaRoundedIcon from "@material-ui/icons/DriveEtaRounded";

import DeviceList from "./DeviceList";
import DeviceItem from "./DeviceItem";

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    marginRight: 15,
    textDecoration: "none",
    color: theme.palette.text.primary,
  },
  cardRoot: {
    maxWidth: "100%",
  },
  cardMedia: {
    height: "100%",
  },
}));

function DemoSimulator({ client }) {
  const classes = useStyles();
  const match = useRouteMatch();

  return (
    <Grid container spacing={3}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            component={NavLink}
            to="/"
          >
            <DriveEtaRoundedIcon />
          </IconButton>
          <Typography
            variant="h6"
            className={classes.title}
            component={NavLink}
            to={`${match.url}/devices`}
          >
            Devices
          </Typography>
          <Typography
            variant="h6"
            className={classes.title}
            component={NavLink}
            to={`${match.url}/trips`}
          >
            Trips
          </Typography>
        </Toolbar>
      </AppBar>
      <Container maxWidth="xl">
        <Grid item xs={12}>
          <Switch>
            <Route path={`${match.url}/devices/:deviceID`}>
              <DeviceItem />
            </Route>
            <Route path={`${match.url}/devices`}>
              <DeviceList />
            </Route>
          </Switch>
        </Grid>
      </Container>
    </Grid>
  );
}

export default withApollo(DemoSimulator);
