import React from "react";
import { Grid, CircularProgress } from "@material-ui/core";

import clsx from "clsx";

import styles from "./styles.module.scss";

const Loading = ({ classes }) => (
  <Grid className={clsx(styles.container, classes)}>
    <CircularProgress />
  </Grid>
);

export default Loading;
