import React from "react";

import styles from "./styles.module.scss";

const Error = ({ message }) => (
  <div className={styles.container}>
    <span className={styles.message}>{message}</span>
  </div>
);

export default Error;
