import React, { useState, useCallback } from "react";
import { Grid } from "@material-ui/core";
import { NavLink, useRouteMatch } from "react-router-dom";

import { tripsList } from "api";

import Button from "components/Button";
import Header from "components/Header";
import Search from "components/Search";
import SimpleTable from "components/SimpleTable";
import Error from "components/Error";
import Filters from "components/Filters";
import Select from "components/Select";
import MultipleSelect from "components/MultipleSelect";

import { getPaginatedList } from "hooks/getPaginatedList";

import { deletePropsFromObject, formatTrips } from "utils/functions";
import { TRIPS_STATUSES_ARRAY } from "consts";
import {
  HEADER_ITEMS,
  FIELDS_ITEMS,
  ENVIRONMENTS_OPTIONS,
  FETCHED_OPTIONS,
} from "./constants";

import styles from "./styles.module.scss";

function TripsList() {
  const [search, setSearch] = useState("");
  const [trips, setTrips] = useState([]);
  const [isFiltersOpen, toggleFiltersModal] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({
    env: "ALL",
    fetched: "ALL",
    status: [],
  });
  const [filters, setFilters] = useState({});
  const [pagination, setPagination] = useState({
    page: 1,
    perPage: 20,
    totalEntries: 0,
  });
  const { url } = useRouteMatch();

  const formattedTrips = useCallback((trips) => {
    const formattedTrips = formatTrips(trips);
    setTrips(formattedTrips);
  }, []);

  const { loading, error } = getPaginatedList({
    setPagination,
    setData: formattedTrips,
    search,
    currentPagination: pagination,
    query: tripsList,
    type: "trips",
    filters,
  });

  const handleFiltersModal = () => toggleFiltersModal(!isFiltersOpen);

  const changeStatus = (event) => {
    const { value } = event.target;
    setSelectedFilters({ ...selectedFilters, status: value });
  };

  const changeEnv = (event) => {
    const { value } = event.target;
    setSelectedFilters({ ...selectedFilters, env: value });
  };

  const changeFetched = (event) => {
    const { value } = event.target;
    setSelectedFilters({ ...selectedFilters, fetched: value });
  };

  const applyFilters = () => {
    const formattedFilters = deletePropsFromObject(["ALL"], selectedFilters);
    if (!selectedFilters?.status?.length) delete formattedFilters.status;
    Object.keys(formattedFilters).length
      ? setFilters(formattedFilters)
      : setFilters({});
    toggleFiltersModal(false);
  };

  const handleChangePage = (_event, page) => {
    setPagination({ ...pagination, page: page + 1 });
  };

  const changeSearch = (event) => {
    setSearch(event.target.value);
  };

  if (error) return <Error message={error.message} />;

  return (
    <>
      <Grid item xs={12}>
        <Header item title="Trips">
          <Button
            iconType="add"
            title="Import"
            component={NavLink}
            to={`${url}/import`}
          />
        </Header>
      </Grid>
      <Grid item xs={11}>
        <Search
          size="small"
          placeholder="Trip Name"
          variant="outlined"
          type="search"
          value={search}
          onChange={changeSearch}
          fullWidth
        />
      </Grid>
      <Grid item xs={1} className={styles.filterIconContainer}>
        <Filters
          isOpen={isFiltersOpen}
          toggleModal={handleFiltersModal}
          apply={applyFilters}
        >
          <Grid className={styles.modalContent}>
            <Select
              name="env"
              selectedValue={selectedFilters.env}
              onChange={changeEnv}
              label="Environments"
              values={ENVIRONMENTS_OPTIONS}
              fullWidth
              required
            />
            <Select
              name="fetched"
              selectedValue={selectedFilters.fetched}
              onChange={changeFetched}
              label="Type"
              values={FETCHED_OPTIONS}
              fullWidth
              required
            />
            <MultipleSelect
              values={selectedFilters.status}
              label="Select statuses"
              data={TRIPS_STATUSES_ARRAY}
              handleChange={changeStatus}
            />
          </Grid>
        </Filters>
      </Grid>
      <Grid item xs={12}>
        <SimpleTable
          header={HEADER_ITEMS}
          itemsFields={FIELDS_ITEMS}
          items={trips}
          loading={loading}
          showPagination
          totalEntries={pagination.totalEntries}
          perPage={pagination.perPage}
          page={pagination.page}
          handleChangePage={handleChangePage}
        />
      </Grid>
    </>
  );
}

export default TripsList;
