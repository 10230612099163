import React from "react";
import { Grid } from "@material-ui/core";
import { withRouter, Switch, Route } from "react-router-dom";
import { flowRight } from "lodash";
import DeviceItem from "routes/platform/Devices/DeviceItem";
import DeviceList from "routes/platform/Devices/DevicesList";

function DevicePage({ match }) {
  return (
    <Grid container spacing={3}>
      <Switch>
        <Route path={`${match.url}/:deviceID`}>
          <DeviceItem />
        </Route>
        <Route path={`${match.url}`}>
          <DeviceList />
        </Route>
      </Switch>
    </Grid>
  );
}

export default flowRight([withRouter])(DevicePage);
