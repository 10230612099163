import React from "react";

import styles from "./styles.module.scss";

const NoData = ({ text = "No Data" }) => (
  <div className={styles.container}>
    <span className={styles.text}>{text}</span>
  </div>
);
export default NoData;
