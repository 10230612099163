import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { useSnackbar } from "notistack";
import { flowRight } from "lodash";
import { withApollo } from "react-apollo";
import moment from "moment";

import {
  tripFetchedSubscription,
  importTripsQuery,
  importTripsMutation,
} from "api";

import Header from "components/Header";
import Select from "components/Select";
import TextField from "components/TextField";
import Button from "components/Button";
import SimpleTable from "components/SimpleTable";
import NoData from "components/NoData";

import { ENVIRONMENTS, NOTIFICATION_TYPES, ROUTES, DATE_FORMATS } from "consts";
import { HEADER_ITEMS, FIELDS_ITEMS } from "./constants";
import { formatDuration } from "utils/functions";

import styles from "./styles.module.scss";

function ImportTrips({ client, history }) {
  const [deviceId, setDeviceId] = useState("");
  const [tripIds, setTripIds] = useState([]);
  const [tripId, setTripId] = useState("");
  const [env, setEnv] = useState("");
  const [trips, setTrips] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    page: 0,
    perPage: 20,
    totalEntries: 0,
  });
  const { enqueueSnackbar } = useSnackbar();

  const changeEnv = (event) => {
    const { value } = event.target;
    setEnv(value);
    setTripIds([]);
    setTripId("");
    setPagination({ page: 1, perPage: 20, totalEntries: 0 });
  };

  const changeDeviceId = (event) => {
    const { value } = event.target;
    setDeviceId(value);
    setTripIds([]);
  };

  const changeTripIds = (tripId) => {
    const selectedIndex = tripIds.indexOf(tripId);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = [...tripIds, tripId];
    } else {
      newSelected = tripIds.filter(
        (selectedTripId) => selectedTripId !== tripId
      );
    }
    setTripIds(newSelected);
  };

  const handleChangePage = (_event, page) => {
    const nextPage = page + 1;
    let paginationProps = {};
    if (pagination.page === page) {
      paginationProps = {
        page: nextPage,
        before: pagination.endCursor,
        after: null,
        last: pagination.perPage,
        first: null,
      };
    } else {
      paginationProps = {
        page: nextPage,
        after: pagination.endCursor,
        before: null,
        first: pagination.perPage,
      };
    }
    setPagination({ ...pagination, ...paginationProps });
  };

  useEffect(() => {
    const variablesHandler = () => {
      let variables = { env, pagination: { last: pagination.perPage } };
      if (tripId !== "") {
        variables.tripId = tripId;
      }
      if (deviceId !== "") {
        variables.deviceId = deviceId;
      }
      if (pagination.before) {
        variables.pagination.before = pagination.before;
      }
      if (pagination.after) {
        variables.pagination.after = pagination.after;
      }
      if (pagination.first) {
        variables.pagination.first = pagination.first;
        delete variables.pagination.last;
      }

      return variables;
    };

    const fetchTrips = () => {
      setLoading(true);
      const variables = variablesHandler();
      client
        .query({
          query: importTripsQuery,
          variables,
        })
        .then(({ data }) => {
          const { entities } = data.xgTrips;
          const formattedTrips = entities?.map((trip) => {
            const { startTime, endTime } = trip;
            return {
              ...trip,
              startTime: moment(startTime).format(DATE_FORMATS.FULL_DATE),
              endTime: moment(endTime).format(DATE_FORMATS.FULL_DATE),
              duration: formatDuration(endTime, startTime),
            };
          });
          setTrips(formattedTrips);
          if (pagination.totalEntries > data.xgTrips.pagination.totalEntries) {
            delete data.xgTrips.pagination.totalEntries;
          }
          setPagination(
            (pagination) =>
              (pagination = { ...pagination, ...data.xgTrips.pagination })
          );
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    };

    if (env !== "") {
      fetchTrips();
    }
  }, [
    env,
    tripId,
    deviceId,
    pagination.page,
    client,
    pagination.totalEntries,
    enqueueSnackbar,
    pagination.perPage,
    pagination.before,
    pagination.after,
    pagination.first,
  ]);

  useEffect(() => {
    client
      .subscribe({
        query: tripFetchedSubscription,
      })
      .subscribe({
        next({ data }) {
          enqueueSnackbar(`Trip Fetched ${data.tripFetched.id}`, {
            variant: NOTIFICATION_TYPES.SUCCESS,
          });
        },
        error(error) {
          enqueueSnackbar(error.message, { variant: NOTIFICATION_TYPES.ERROR });
        },
      });
  });

  const importTrips = () => {
    client
      .mutate({
        mutation: importTripsMutation,
        variables: { tripIds, env },
      })
      .then(() => {
        enqueueSnackbar("Trips Start Fetch", {
          variant: NOTIFICATION_TYPES.SUCCESS,
        });
        history.push(`/${ROUTES.TRIPS}`);
      })
      .catch((error) =>
        enqueueSnackbar(error.message, { variant: NOTIFICATION_TYPES.ERROR })
      );
  };

  return (
    <>
      <Grid item xs={12}>
        <Header item title="Import Trips" />
      </Grid>
      <Grid item className={styles.container} xs={12}>
        <Grid item className={styles.inputsContainer} xs={10}>
          <Select
            selectedValue={env}
            onChange={changeEnv}
            containerClassName={styles.selectContainer}
            values={ENVIRONMENTS}
            fullWidth
            placeholder="Select Environment"
            required
          />
          <TextField
            id="Delay"
            fullWidth
            type="text"
            value={deviceId}
            onChange={changeDeviceId}
            name="delay"
            label="Device ID"
            placeholder="Insert Device Id"
            classNames={styles.textFieldContainer}
            isDisabled={!env}
          />
          <TextField
            id="Delay"
            fullWidth
            type="text"
            value={tripId}
            onChange={(event) => setTripId(event.target.value)}
            name="delay"
            label="Trip ID"
            placeholder="Insert Trip Id"
            classNames={styles.textFieldContainer}
            isDisabled={!env}
          />
        </Grid>
        <Grid className={styles.buttonsContainer} item xs={2}>
          <Button
            color="primary"
            title="Save"
            onClick={importTrips}
            iconType="save"
            isDisabled={!tripIds.length}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {env ? (
          <SimpleTable
            header={HEADER_ITEMS}
            itemsFields={FIELDS_ITEMS}
            items={trips}
            toggleSelectedIds={changeTripIds}
            selectedIds={tripIds}
            loading={loading}
            showPagination
            totalEntries={pagination.totalEntries}
            perPage={pagination.perPage}
            page={pagination.page}
            handleChangePage={handleChangePage}
            isChecked
          />
        ) : (
          <NoData text="Select Environment" />
        )}
      </Grid>
    </>
  );
}

export default flowRight([withRouter, withApollo])(ImportTrips);
