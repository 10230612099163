import * as React from "react";
import { GraphiQL } from "graphiql";
import { getAuthorizationToken } from "utils/functions";
import "graphiql/graphiql.min.css";

const fetcher = (graphQLParams) =>
  fetch(`${process.env.REACT_APP_API_URL}/api/platform`, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      Authorization: getAuthorizationToken(),
    },
    body: JSON.stringify(graphQLParams),
  })
    .then((response) => response.json())
    .catch(() => {});

const GraphiqlWrapper = () => <GraphiQL fetcher={fetcher} />;

export default GraphiqlWrapper;
