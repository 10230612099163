import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import { getDevices } from "api";
import Header from "components/Header";
import Search from "components/Search";
import SimpleTable from "components/SimpleTable";
import Error from "components/Error";
import { getPaginatedList } from "hooks/getPaginatedList";
import { HEADER_ITEMS, FIELDS_ITEMS } from "./constants";
import { CreateDevice } from "./components";

const DeviceList = () => {
  const [search, setSearch] = useState("");
  const [devices, setDevices] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    perPage: 20,
    totalEntries: 0,
  });

  const { loading, error } = getPaginatedList({
    setPagination,
    setData: setDevices,
    search,
    currentPagination: pagination,
    query: getDevices,
    type: "devices",
  });

  const handleChangePage = (_event, page) =>
    setPagination({ ...pagination, page: page + 1 });

  const changeSearch = (event) => setSearch(event.target.value);

  if (error) return <Error message={error.message} />;

  return (
    <>
      <Grid item xs={12}>
        <Header title="Devices">
          <CreateDevice
            refetchVariables={{
              pagination: {
                page: pagination.page,
                perPage: pagination.perPage,
              },
              search,
            }}
          />
        </Header>
      </Grid>
      <Grid item xs={12}>
        <Search
          size="small"
          placeholder="Device Name"
          type="search"
          value={search}
          onChange={changeSearch}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <SimpleTable
          header={HEADER_ITEMS}
          itemsFields={FIELDS_ITEMS}
          items={devices}
          loading={loading}
          showPagination
          totalEntries={pagination.totalEntries}
          perPage={pagination.perPage}
          page={pagination.page}
          handleChangePage={handleChangePage}
        />
      </Grid>
    </>
  );
};

export default DeviceList;
