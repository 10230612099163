import gql from "graphql-tag";

export const createTask = gql`
  mutation createTask($env: XgEnv!, $name: String!, $description: String) {
    createTask(env: $env, name: $name, description: $description) {
      id
    }
  }
`;

export const updateTask = gql`
  mutation updateTask($id: ID!, $name: String, $description: String) {
    updateTask(id: $id, name: $name, description: $description) {
      id
      name
      description
    }
  }
`;

export const startSimulation = gql`
  mutation startSimulation($id: ID!) {
    startSimulation(id: $id) {
      id
      status
    }
  }
`;

export const pauseSimulation = gql`
  mutation pauseSimulation($id: ID!, $time: Int!) {
    pauseSimulation(id: $id, time: $time) {
      id
      status
    }
  }
`;

export const stopSimulation = gql`
  mutation stopSimulation($id: ID!) {
    stopSimulation(id: $id) {
      id
      status
    }
  }
`;

export const deleteTask = gql`
  mutation deleteTask($id: ID!) {
    deleteTask(id: $id)
  }
`;
