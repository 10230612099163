import * as React from "react";
import { CopyButton } from "./components";

export const HEADER_ITEMS = [
  "Device Name",
  "Device ID",
  "Client Name",
  "Description",
];

export const FIELDS_ITEMS = [
  "name",
  (record) => (
    <>
      {record.id}
      <CopyButton text={record.id} />
    </>
  ),
  "client.name",
  "description",
];
