import React from "react";
import ReactDOM from "react-dom";
import { ApolloProvider } from "react-apollo";
import { createAbsintheSocketLink } from "@absinthe/socket-apollo-link";
import { Socket as PhoenixSocket } from "phoenix";
import * as AbsintheSocket from "@absinthe/socket";
import { setContext } from "apollo-link-context";
import { createLink } from "apollo-absinthe-upload-link";
/* eslint-disable */
import { ApolloLink, split } from "apollo-link";
import { hasSubscription } from "@jumpn/utils-graphql";
import ApolloClient from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { onError } from "apollo-link-error";
/* eslint-enable */

import "typeface-roboto-mono";

import { getAuthorizationToken } from "utils/functions";

import "./index.css";
import App from "./App";

const wsLink = createAbsintheSocketLink(
  AbsintheSocket.create(
    new PhoenixSocket(`ws://simulator.modustools.com/api/platform`, {
      params: () => ({ Authorization: getAuthorizationToken() }),
    })
  )
);

const authLink = setContext((_, { headers }) => {
  const Authorization = getAuthorizationToken();
  if (Authorization) {
    return { headers: { ...headers, Authorization } };
  }
  return { headers };
});

const uploadLink = createLink({
  uri: `${process.env.REACT_APP_API_URL}/api/platform`,
});

const link = split(
  (operation) => hasSubscription(operation.query),
  wsLink,
  authLink.concat(uploadLink)
);

const handleError = ({ graphQLErrors, operation }) => {};

const client = new ApolloClient({
  cache: new InMemoryCache({}),
  link: ApolloLink.from([onError(handleError), link]),
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>,
  document.getElementById("root")
);
