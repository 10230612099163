import React from "react";
import { Container, Grid } from "@material-ui/core";
import { withRouter, Switch, Route } from "react-router-dom";
import { flowRight } from "lodash";

import "./ie.polyfills";

import Menu from "components/Menu";
import DevicePage from "./Devices";
import TripPage from "./Trips";
import TaskPage from "./Tasks";

const PlatformSimulator = ({ match }) => {
  return (
    <Grid container spacing={2}>
      <Menu />
      <Container maxWidth="xl">
        <Switch>
          <Route path={`${match.url}/devices`}>
            <DevicePage />
          </Route>
          <Route path={`${match.url}/trips`}>
            <TripPage />
          </Route>
          <Route path={`${match.url}/tasks`}>
            <TaskPage />
          </Route>
          <Route path={`${match.url}`}></Route>
        </Switch>
      </Container>
    </Grid>
  );
};
export default flowRight([withRouter])(PlatformSimulator);
