import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { useRouteMatch } from "react-router-dom";
import { withApollo } from "react-apollo";
import { useSnackbar } from "notistack";

import { getDevicesIds, TripDevices, createTripStep, taskItem } from "api";

import SimpleTable from "components/SimpleTable";
import Error from "components/Error";
import Button from "components/Button";
import AsyncSelect from "components/AsyncSelect";

import { NOTIFICATION_TYPES, MESSAGES } from "consts";
import { HEADER_ITEMS, FIELDS_ITEMS } from "./constant";

import styles from "./styles.module.scss";

const Devices = ({ client, tripID, goBack, closeModal }) => {
  const [originDevices, setOriginDevices] = useState([]);
  const [selectedDeviceId, setSelectedDeviceId] = useState("");
  const [devices, setDevices] = useState({});
  const [searchDemoDevice, setSearchDemoDevice] = useState("");
  const [pagination, setPagination] = useState({
    page: 1,
    perPage: 20,
    totalEntries: 0,
  });
  const { params } = useRouteMatch();
  const { enqueueSnackbar } = useSnackbar();

  const devicesEdited = () => {
    enqueueSnackbar(`Devices ${MESSAGES.EDITED}`, {
      variant: NOTIFICATION_TYPES.SUCCESS,
    });
    closeModal();
  };

  const [editDevices] = useMutation(createTripStep, {
    onCompleted: devicesEdited,
    refetchQueries: [
      {
        query: taskItem,
        variables: { taskID: params.taskID },
      },
    ],
  });

  const formattingDevices = (data) => {
    const ids = Object.values(data);
    return ids.map((item) => ({
      originDeviceId: item.originDeviceId,
      deviceId: item.demoDeviceId,
    }));
  };

  const saveDevices = () => {
    const { taskID } = params;
    const data = formattingDevices(devices);
    editDevices({
      variables: { deviceIds: data, tripId: tripID, taskId: taskID },
    });
  };

  const setOriginDevicesToState = ({ trip }) => {
    const { originDevicesTrips } = trip;
    const devices = [];
    originDevicesTrips.map((item) => devices.push(item.originDevice));
    setOriginDevices(devices);
  };

  const { loading, error } = useQuery(TripDevices, {
    onCompleted: setOriginDevicesToState,
    variables: {
      tripID,
    },
  });

  const getDevices = async () => {
    const { taskID } = params;
    const { data } = await client.query({
      query: getDevicesIds,
      variables: {
        availableForTask: taskID,
        search: searchDemoDevice,
        pagination: { page: pagination.page, perPage: pagination.perPage },
      },
    });
    const { devices } = data;
    const formattedDevices = devices.entities.map((item) => ({
      id: item.id,
      label: item.id,
    }));
    return {
      options: formattedDevices,
      hasMore:
        devices.pagination.page <
        devices.pagination.totalEntries / devices.pagination.perPage,
    };
  };

  const changeDemoDevice = (demoDeviceId) => {
    setDevices({
      ...devices,
      [selectedDeviceId]: {
        demoDeviceId: demoDeviceId,
        originDeviceId: selectedDeviceId,
      },
    });
  };

  const handleChangePage = (page) =>
    setPagination({ ...pagination, page: page + 1 });

  const resetPage = () => setPagination({ ...pagination, page: 1 });

  if (error) {
    return <Error message={error.message} />;
  }

  return (
    <>
      <div className={styles.contentContainer}>
        <SimpleTable
          header={HEADER_ITEMS}
          itemsFields={FIELDS_ITEMS}
          items={originDevices}
          toggleSelectedIds={setSelectedDeviceId}
          loading={loading}
        >
          <AsyncSelect
            id={params?.id}
            record="Select Demo Device..."
            onItemSelected={changeDemoDevice}
            handleChangePage={handleChangePage}
            page={pagination.page}
            loadPaginatedOptions={getDevices}
            resetPage={resetPage}
            value={searchDemoDevice}
            setInputValue={setSearchDemoDevice}
          />
        </SimpleTable>
      </div>
      <Grid className={styles.buttonsContainer}>
        <Button iconType="back" color="default" title="Back" onClick={goBack} />
        <Button
          isDisabled={originDevices.length !== Object.keys(devices).length}
          iconType="save"
          title="Save"
          onClick={saveDevices}
        />
      </Grid>
    </>
  );
};

export default withApollo(Devices);
