import React from "react";
import clsx from "clsx";
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

import { STEPS_TYPES, TRIPS_SUMILATED_SATUSES } from "consts";
import Loader from "components/Loader";
import NoData from "components/NoData";

import styles from "./styles.module.scss";

const Row = ({ item, allowDelete, deleteItem }) => {
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow
        role="checkbox"
        tabIndex={-1}
        key={item.id}
        className={clsx(
          item.status === TRIPS_SUMILATED_SATUSES.RUNNING && styles.active,
          item.status === TRIPS_SUMILATED_SATUSES.SIMULATED && styles.simulated
        )}
      >
        <TableCell>
          {item.type === STEPS_TYPES.TRIP && (
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          )}
        </TableCell>
        <TableCell>{item?.stepsTrip?.trip?.id || "-"}</TableCell>
        <TableCell>{item?.stepsTrip?.trip?.status || "-"}</TableCell>
        <TableCell>{item.type}</TableCell>
        <TableCell>{item.status || "-"}</TableCell>
        {item.type === STEPS_TYPES.DELAY && (
          <TableCell>{`${item.delay?.min} sec`}</TableCell>
        )}
        {item.type === STEPS_TYPES.RANDOM_DELAY && (
          <TableCell>{`${item.delay?.min} - ${item.delay?.max} sec`}</TableCell>
        )}
        {item.type === STEPS_TYPES.LOOP && (
          <TableCell>{`${item.loop?.count} iter`}</TableCell>
        )}
        {item.type === STEPS_TYPES.TRIP && (
          <TableCell>{item?.stepsTrip?.trip?.distance} km</TableCell>
        )}
        {allowDelete && (
          <TableCell
            className={styles.deleteItem}
            onClick={() => deleteItem && deleteItem(item.id)}
          >
            x
          </TableCell>
        )}
      </TableRow>
      {item.type === STEPS_TYPES.TRIP && (
        <TableRow>
          <TableCell className={styles.collapsibleCell} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Typography variant="h6" gutterBottom component="div">
                  Devices
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>Origin Device ID</TableCell>
                      <TableCell>Device ID for a Trip</TableCell>
                      <TableCell>Device ID</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {item?.stepsTrip?.originDevicesTripsStepsTrips?.map(
                      ({ originDevicesTrip, device, id }) => (
                        <TableRow key={id}>
                          <TableCell component="th" scope="row">
                            {originDevicesTrip?.originDevice?.id}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {device?.id}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {id}
                          </TableCell>
                        </TableRow>
                      )
                    )}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
};

const StepsList = ({ data, deleteItem, allowDelete, loading }) => {
  if (loading) {
    return <Loader />;
  }

  return (
    <TableContainer component={Paper}>
      {data?.length > 0 ? (
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>UUID</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Step Status</TableCell>
              <TableCell>Info</TableCell>
              {allowDelete && <TableCell></TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((item) => (
              <Row
                key={item.id}
                item={item}
                allowDelete={allowDelete}
                deleteItem={deleteItem}
              />
            ))}
          </TableBody>
        </Table>
      ) : (
        <NoData text="No steps added" />
      )}
    </TableContainer>
  );
};

export default StepsList;
