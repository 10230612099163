import React from "react";
import { Grid, Paper, Typography } from "@material-ui/core";
import { useQuery } from "@apollo/react-hooks";
import { useRouteMatch } from "react-router-dom";
import { deviceItem } from "api";
import Loader from "components/Loader";
import Header from "components/Header";
import Error from "components/Error";
import { valueOrDash } from "utils/functions";

import styles from "./styles.module.scss";

const DeviceItem = () => {
  const { params } = useRouteMatch();
  const { data, loading, error } = useQuery(deviceItem, {
    variables: { deviceID: params.deviceID },
  });

  if (loading && !data?.device) {
    return <Loader />;
  }

  const { device } = data;

  if (error) {
    return <Error message={error.message} />;
  }

  return (
    <>
      <Grid item xs={12}>
        <Header title={`Device: ${device?.id}`} />
      </Grid>
      <Grid item xs={12}>
        <Paper className={styles.infoContainer}>
          {/* <Typography>
            <span className={styles.label}>Status:</span>{" "}
            {device?.status || "-"}
          </Typography> */}
          <Typography>
            <span className={styles.label}>Name:</span>{" "}
            {valueOrDash(device?.name)}
          </Typography>
          <Typography>
            <span className={styles.label}>Description:</span>{" "}
            {valueOrDash(device?.description)}
          </Typography>
          {/* <Typography>
            <span className={styles.label}>DriverUuid:</span>{" "}
            {valueOrDash(device?.driverUuid)}
          </Typography>
            <Typography>
            <span className={styles.label}>LoopTrips:</span>{" "}
            {valueOrDash(device?.loopTrips)}
            </Typography> */}
        </Paper>
      </Grid>
    </>
  );
};

export default DeviceItem;
