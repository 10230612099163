import React from "react";
import { useTheme } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import { useStyles, getStyles, MenuProps } from "./styles";
import styles from "./stules.module.scss";

const MultipleSelect = ({ values, data, handleChange, label }) => {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <div className={styles.container}>
      <FormControl className={classes.formControl}>
        <InputLabel id="demo-mutiple-name-label">{label}</InputLabel>
        <Select
          labelId="demo-mutiple-name-label"
          id="demo-mutiple-name"
          value={values}
          onChange={handleChange}
          input={<Input />}
          MenuProps={MenuProps}
          multiple
        >
          {data.map((item) => (
            <MenuItem
              key={item}
              value={item}
              style={getStyles(item, values, theme)}
            >
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default MultipleSelect;
