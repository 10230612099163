/* eslint-disable */
import moment from "moment";
import { STEPS_TYPES, DATE_FORMATS } from "consts";

export const getAuthorizationToken = () => {
  const token = localStorage.getItem("token");
  return token ? `Bearer ${token}` : null;
};

export const getModalStyle = () => {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    outline: "none",
  };
};

export const valueOrDash = (value) => value || "—";

export const deletePropsFromObject = (props, object) => {
  if (!Array.isArray(props)) {
    return object;
  }
  const objectCopy = { ...object };
  for (const key in objectCopy) {
    if (props.includes(objectCopy[key])) {
      delete objectCopy[key];
    }
  }
  return objectCopy;
};

export const secToMillisec = (timeInSeconds) => timeInSeconds * 1000;

export const convertStepsTimeToSeconds = (steps = []) => {
  return steps.map((step) => {
    if (
      STEPS_TYPES.DELAY === step?.type ||
      STEPS_TYPES.RANDOM_DELAY === step?.type
    ) {
      const { max, min } = step?.delay;
      return {
        ...step,
        delay: { ...step.delay, max: max / 1000, min: min / 1000 },
      };
    }
    return step;
  });
};

export const formatDuration = (endTime, startTime) => {
  const duration = moment.duration(moment(endTime) - moment(startTime));
  const timeArray = [
    `${duration.get("hours")}h`,
    ` ${duration.get("minutes")}m`,
    ` ${duration.get("seconds")}s`,
  ].filter((item) => item[1] > 0);
  return timeArray.toString();
};

export const formatTrips = (trips) => {
  return trips?.map((trip) => {
    const { stopTimestamp, startTimestamp } = trip;
    return {
      ...trip,
      startTimestamp: moment(startTimestamp).format(DATE_FORMATS.FULL_DATE),
      stopTimestamp: moment(stopTimestamp).format(DATE_FORMATS.FULL_DATE),
      duration: formatDuration(stopTimestamp, startTimestamp),
    };
  });
};
