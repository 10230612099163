import gql from "graphql-tag";

import { pagination } from "api/props/pagination";

export const getTasks = gql`
  query tasks($search: String $pagination: PaginationParams $filter: FilterTask) {
    tasks(searchValue: $search pagination: $pagination, filter: $filter) {
      entities {
        id
        status
        name
        description
        env
      }
      pagination{
        ${pagination}
      }
    }
  }
`;

export const taskItem = gql`
  query task($taskID: ID!) {
    task(id: $taskID) {
      id
      name
      description
      steps {
        delay {
          id
          max
          min
        }
        id
        loop {
          count
          id
        }
        stepsTrip {
          id
          originDevicesTripsStepsTrips {
            id
            device {
              id
            }
            originDevicesTrip {
              id
              originDevice {
                id
              }
            }
          }
          trip {
            distance
            status
            id
          }
        }
        type
      }
    }
  }
`;

export const taskItemInfo = gql`
  query task($taskID: ID!) {
    task(id: $taskID) {
      id
      name
      description
      status
      env
      steps {
        delay {
          id
          max
          min
        }
        id
        loop {
          count
          id
        }
        status
        stepsTrip {
          id
          originDevicesTripsStepsTrips {
            id
            device {
              id
            }
            originDevicesTrip {
              id
              originDevice {
                id
              }
            }
          }
          trip {
            distance
            status
            id
          }
        }
        type
      }
    }
  }
`;
