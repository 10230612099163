import React from "react";
import AddBoxIcon from "@material-ui/icons/AddBox";
import SaveIcon from "@material-ui/icons/Save";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import EditIcon from "@material-ui/icons/Edit";
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";
import { Button as MUButton } from "@material-ui/core";

import styles from "./styles.module.scss";

const Button = ({
  onClick,
  title,
  variant = "contained",
  color = "primary",
  isDisabled,
  type,
  component,
  iconType,
  to,
  style,
}) => (
  <MUButton
    style={style}
    type={type}
    className={styles.container}
    variant={variant}
    color={color}
    onClick={onClick}
    disabled={isDisabled}
    component={component}
    to={to}
    startIcon={
      (iconType === "save" && <SaveIcon />) ||
      (iconType === "copy" && <FileCopyIcon />) ||
      (iconType === "back" && <ArrowBackIcon />) ||
      (iconType === "cancel" && <CancelPresentationIcon />) ||
      (iconType === "add" && <AddBoxIcon />) ||
      (iconType === "edit" && <EditIcon />)
    }
  >
    {title}
  </MUButton>
);

export default Button;
