import React, { useState, useCallback } from "react";
import { Grid } from "@material-ui/core";

import Search from "components/Search";
import Error from "components/Error";
import SimpleTable from "components/SimpleTable";
import Devices from "routes/platform/Tasks/Devices";
import CancelIcon from "@material-ui/icons/Cancel";

import { TaskTripsQuery } from "api";

import { getPaginatedList } from "hooks/getPaginatedList";

import Select from "components/Select";
import MultipleSelect from "components/MultipleSelect";
import Filters from "components/Filters";

import { deletePropsFromObject, formatTrips } from "utils/functions";

import { TRIPS_STATUSES_ARRAY } from "consts";
import {
  HEADER_ITEMS,
  FIELDS_ITEMS,
  ENVIRONMENTS_OPTIONS,
  FETCHED_OPTIONS,
} from "./constants";

import styles from "./styles.module.scss";

const Trips = ({ closeModal }) => {
  const [modalStep, setModalStep] = useState(1);
  const [selectedTripId, setTripId] = useState("");
  const [search, setSearch] = useState("");
  const [trips, setTrips] = useState([]);
  const [filters, setFilters] = useState({});
  const [isFiltersOpen, toggleFiltersModal] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({
    env: "ALL",
    fetched: "ALL",
    status: [],
  });
  const [pagination, setPagination] = useState({
    page: 1,
    perPage: 20,
    totalEntries: 0,
  });

  const formattedTrips = useCallback((trips) => {
    const formattedTrips = formatTrips(trips);
    setTrips(formattedTrips);
  }, []);

  const { loading, error } = getPaginatedList({
    setPagination,
    setData: formattedTrips,
    search,
    currentPagination: pagination,
    query: TaskTripsQuery,
    type: "trips",
    filters,
  });

  const handleFiltersModal = () => toggleFiltersModal(!isFiltersOpen);

  const changeStatus = (event) => {
    const { value } = event.target;
    setSelectedFilters({ ...selectedFilters, status: value });
  };

  const changeEnv = (event) => {
    const { value } = event.target;
    setSelectedFilters({ ...selectedFilters, env: value });
  };

  const changeFetched = (event) => {
    const { value } = event.target;
    setSelectedFilters({ ...selectedFilters, fetched: value });
  };

  const applyFilters = () => {
    const formattedFilters = deletePropsFromObject(["ALL"], selectedFilters);
    if (!selectedFilters?.status?.length) delete formattedFilters.status;
    Object.keys(formattedFilters).length
      ? setFilters(formattedFilters)
      : setFilters("");
    toggleFiltersModal(false);
  };

  const selectTrip = (tripID) => {
    setTripId(tripID);
    setModalStep(2);
  };

  const handleChangePage = (_event, page) => {
    setPagination({ ...pagination, page: page + 1 });
  };

  const changeSearch = (event) => {
    setSearch(event.target.value);
  };

  const goBack = () => {
    setModalStep(modalStep - 1);
  };

  if (error) {
    return <Error message={error.message} />;
  }

  return (
    <div className={styles.tripsTable}>
      <div className={styles.close}>
        <CancelIcon onClick={closeModal} className={styles.closeIcon} />
      </div>
      {modalStep === 1 && (
        <Grid container spacing={3}>
          <Grid item xs={11}>
            <Search
              size="small"
              placeholder="Task Name"
              variant="outlined"
              type="search"
              value={search}
              onChange={changeSearch}
              fullWidth
            />
          </Grid>
          <Grid item xs={1} className={styles.filterIconContainer}>
            <Filters
              isOpen={isFiltersOpen}
              toggleModal={handleFiltersModal}
              apply={applyFilters}
            >
              <Grid className={styles.modalContent}>
                <Select
                  name="env"
                  selectedValue={selectedFilters.env}
                  onChange={changeEnv}
                  label="Environments"
                  values={ENVIRONMENTS_OPTIONS}
                  fullWidth
                  required
                />
                <Select
                  name="fetched"
                  selectedValue={selectedFilters.fetched}
                  onChange={changeFetched}
                  label="Type"
                  values={FETCHED_OPTIONS}
                  fullWidth
                  required
                />
                <MultipleSelect
                  values={selectedFilters.status}
                  label="Select statuses"
                  data={TRIPS_STATUSES_ARRAY}
                  handleChange={changeStatus}
                />
              </Grid>
            </Filters>
          </Grid>
          <Grid item className={styles.tableWrapper} xs={12}>
            <SimpleTable
              header={HEADER_ITEMS}
              itemsFields={FIELDS_ITEMS}
              items={trips}
              selectItem={selectTrip}
              loading={loading}
              showPagination
              totalEntries={pagination.totalEntries}
              perPage={pagination.perPage}
              page={pagination.page}
              handleChangePage={handleChangePage}
            />
          </Grid>
        </Grid>
      )}
      {modalStep === 2 && (
        <div className={styles.contentContainer}>
          <Devices
            tripID={selectedTripId}
            goBack={goBack}
            closeModal={closeModal}
          />
        </div>
      )}
    </div>
  );
};

export default Trips;
