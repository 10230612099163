import gql from "graphql-tag";

export const createDelayStep = gql`
  mutation createDelayStep($delay: Int!, $taskId: ID!) {
    createDelayStep(delay: $delay, taskId: $taskId) {
      delay {
        id
        max
        min
      }
      id
      type
    }
  }
`;

export const createLoopStep = gql`
  mutation createLoopStep($count: Int!, $taskId: ID!) {
    createLoopStep(count: $count, taskId: $taskId) {
      id
      loop {
        count
        id
      }
      type
    }
  }
`;

export const createRandomDelayStep = gql`
  mutation createRandomDelayStep($max: Int!, $min: Int!, $taskId: ID!) {
    createRandomDelayStep(max: $max, min: $min, taskId: $taskId) {
      delay {
        id
        max
        min
      }
      id
      type
    }
  }
`;

export const deleteStep = gql`
  mutation deleteStep($id: ID!) {
    deleteStep(id: $id)
  }
`;

export const createTripStep = gql`
  mutation createTripStep(
    $deviceIds: [TripStepDeviceId!]!
    $tripId: UUID!
    $taskId: ID!
  ) {
    createTripStep(deviceIds: $deviceIds, tripId: $tripId, taskId: $taskId) {
      id
    }
  }
`;
