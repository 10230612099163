import React from "react";
import { Grid } from "@material-ui/core";
import { withRouter, Switch, Route } from "react-router-dom";
import { flowRight } from "lodash";

import TaskList from "routes/platform/Tasks/TasksList";
import TaskEdit from "routes/platform/Tasks/TaskEdit";
import TaskItem from "routes/platform/Tasks/TaskItem";
import { ROUTES } from "consts";

function TaskPage({ match }) {
  return (
    <Grid container spacing={3}>
      <Switch>
        <Route path={`${match.url}/:${ROUTES.TASK_ID}/edit`}>
          <TaskEdit />
        </Route>
        <Route path={`${match.url}/:${ROUTES.TASK_ID}`}>
          <TaskItem />
        </Route>
        <Route path={match.url}>
          <TaskList />
        </Route>
      </Switch>
    </Grid>
  );
}

export default flowRight([withRouter])(TaskPage);
