import * as React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import FileCopyIcon from "@material-ui/icons/FileCopy";

interface CopyButtonProps {
  text: string;
}

const CopyButton: React.FC<CopyButtonProps> = ({ text }) => {
  const [visible, setVisible] = React.useState(false);

  const timeoutId = React.useRef<number | null>(null);
  return (
    <Tooltip title="Copied !" open={visible} placement="top">
      <IconButton
        onClick={(event) => {
          event.stopPropagation();
          if (timeoutId.current) {
            clearTimeout(timeoutId.current);
          }

          const selection = window.getSelection();
          const div = document.createElement("div");

          div.textContent = text;
          div.style.cssText = `
              position: absolute;
              opacity: 0;
              left: -9999px;
          `;

          document.body.append(div);

          if (!selection) {
            return;
          }

          try {
            const range = document.createRange();

            range.selectNodeContents(div);
            selection.removeAllRanges();
            selection.addRange(range);

            document.execCommand("copy");
            selection.removeAllRanges();

            setVisible(true);
            timeoutId.current = window.setTimeout(() => {
              setVisible(false);
            }, 800);
            // eslint-disable-next-line
          } catch (e) {}

          document.body.removeChild(div);
        }}
      >
        <FileCopyIcon />
      </IconButton>
    </Tooltip>
  );
};

export default CopyButton;
