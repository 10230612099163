import React, { useState, useEffect } from "react";
import {
  Grid,
  Paper,
  Typography,
  Button,
  ButtonGroup,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import PauseIcon from "@material-ui/icons/Pause";
import StopIcon from "@material-ui/icons/Stop";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { NavLink, useRouteMatch, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { valueOrDash, convertStepsTimeToSeconds } from "utils/functions";
import Header from "components/Header";
import Error from "components/Error";
import Modal from "components/Modal";
import TextField from "components/TextField";
import StepsList from "routes/platform/Tasks/StepsList";
import Loader from "components/Loader";
import {
  startSimulation,
  pauseSimulation,
  stopSimulation,
  taskItemInfo,
  getTasks,
  deleteTask as deleteTaskMutation,
} from "api";
import { REGEXP, MESSAGES, TASK_STATUSES, ROUTES } from "consts";

import styles from "./styles.module.scss";

const TaskItem = () => {
  const { register, handleSubmit, errors } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });
  const [isModalOpen, toggleModal] = useState(false);
  const [isDeleteModalOpen, toggleDeleteModal] = useState(false);
  const [task, setTask] = useState({});
  const [steps, setSteps] = useState([]);
  const { params, url } = useRouteMatch();
  const history = useHistory();
  const { data, loading, error } = useQuery(taskItemInfo, {
    variables: { taskID: params?.taskID },
    fetchPolicy: "cache-and-network",
  });

  const taskDeleted = () => history.push(`/${ROUTES.TASKS}`);

  const refetchTasks = () => [
    {
      query: getTasks,
      variables: {
        filter: {},
        search: null,
        pagination: {
          page: 1,
          perPage: 20,
        },
      },
    },
  ];

  const [startTaskSimulation, startTaskStatus] = useMutation(startSimulation);
  const [pauseTaskSimulation, pauseTaskStatus] = useMutation(pauseSimulation);
  const [stopTaskSimulation, stopTaskStatus] = useMutation(stopSimulation);
  const [deleteTask] = useMutation(deleteTaskMutation, {
    onCompleted: taskDeleted,
    refetchQueries: refetchTasks,
  });

  useEffect(() => {
    setTask(data?.task);
    const formattedSteps = convertStepsTimeToSeconds(data?.task.steps);
    setSteps(formattedSteps);
  }, [loading, data]);

  const pauseTask = ({ pauseDuration }) => {
    pauseTaskSimulation({
      variables: {
        id: task?.id,
        time: +pauseDuration,
      },
    });
    toggleModal(!isModalOpen);
  };

  const showPauseModal = () => toggleModal(!isModalOpen);

  const startTask = () =>
    startTaskSimulation({
      variables: {
        id: task?.id,
      },
    });

  const stopTask = () =>
    stopTaskSimulation({
      variables: {
        id: task?.id,
      },
    });

  const deleteTaskHandler = () =>
    deleteTask({
      variables: {
        id: task?.id,
      },
    });

  const closeModal = () => toggleModal(!isModalOpen);
  const handleDeleteModal = () => toggleDeleteModal(!isDeleteModalOpen);

  if (
    loading ||
    startTaskStatus.loading ||
    pauseTaskStatus.loading ||
    stopTaskStatus.loading
  ) {
    return <Loader />;
  }

  if (error) return <Error message={error.message} />;

  return (
    <>
      <Grid item xs={12}>
        <Header className={styles.buttonsBlock} title={`Task: ${task?.name}`}>
          <ButtonGroup
            variant="contained"
            color="primary"
            aria-label="contained primary button group"
          >
            {task?.status !== TASK_STATUSES.RUNNING && (
              <Button
                startIcon={<PlayArrowIcon />}
                disabled={!task?.steps?.length}
                onClick={startTask}
              >
                Start
              </Button>
            )}
            {task?.status !== TASK_STATUSES.NOT_RUNNING && (
              <Button startIcon={<StopIcon />} onClick={stopTask}>
                Stop
              </Button>
            )}
            {task?.status !== TASK_STATUSES.PAUSED && (
              <Button
                startIcon={<PauseIcon />}
                onClick={showPauseModal}
                disabled={task?.status === TASK_STATUSES.NOT_RUNNING}
              >
                Pause
              </Button>
            )}
            <Button
              startIcon={<EditIcon />}
              component={NavLink}
              to={`${url}/edit`}
            >
              Edit
            </Button>
            <Button
              startIcon={<DeleteForeverIcon />}
              onClick={handleDeleteModal}
              color="secondary"
            >
              Delete
            </Button>
          </ButtonGroup>
        </Header>
      </Grid>
      <Grid item xs={3}>
        <Paper className={styles.infoContainer}>
          <Typography className={styles.field}>
            <span>Status:</span>
            <span>{task?.status || "-"}</span>
          </Typography>
          <Typography className={styles.field}>
            <span>Name:</span>
            <span>{valueOrDash(data?.task.name)}</span>
          </Typography>
          <Typography className={styles.field}>
            <span>Env:</span>
            <span>{task?.env}</span>
          </Typography>
          <Typography className={styles.field}>
            <span className={styles.label}>Description: </span>
            <span>{valueOrDash(task?.description)}</span>
          </Typography>
        </Paper>
      </Grid>
      <Grid item xs={9}>
        <Paper>
          <StepsList data={steps} />
        </Paper>
      </Grid>
      <Modal isOpen={isModalOpen}>
        <form className={styles.modal} onSubmit={handleSubmit(pauseTask)}>
          <div className={styles.TextFieldWrapper}>
            <TextField
              isError={!!errors.name}
              errorMessage={MESSAGES.INCORRECT_VALUE}
              register={register({
                required: true,
                pattern: REGEXP.POSITIVE_INTEGER_NUMBERS_WITHOUT_0,
              })}
              name="pauseDuration"
              label="Pause Duration (in ms)"
              errors={errors}
            />
          </div>
          <Grid className={styles.buttonsBlock} item xs={12}>
            <Button variant="outlined" onClick={closeModal}>
              Close
            </Button>
            <Button variant="outlined" type="submit">
              Save
            </Button>
          </Grid>
        </form>
      </Modal>
      <Modal isOpen={isDeleteModalOpen}>
        <Grid item xs={12} className={styles.modal}>
          <Typography
            component="span"
            className={styles.label}
            justify="center"
            alignItems="center"
          >
            Are you sure?
          </Typography>
          <div className={styles.buttonsBlock}>
            <Button variant="outlined" onClick={handleDeleteModal}>
              Close
            </Button>
            <Button variant="outlined" onClick={deleteTaskHandler}>
              Delete
            </Button>
          </div>
        </Grid>
      </Modal>
    </>
  );
};

export default TaskItem;
