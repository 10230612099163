import React, { useState } from "react";
import { components } from "react-select";
import { AsyncPaginateBase } from "react-select-async-paginate";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import clsx from "clsx";
import styles from "./styles.module.scss";
import "./styles.scss";

const AsyncSelect = ({
  label,
  isDisabled,
  record,
  onItemSelected,
  type,
  id,
  loadPaginatedOptions,
  handleChangePage,
  page,
  resetPage,
  setInputValue,
  value,
  ...props
}) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const onMenuOpen = () => {
    setMenuIsOpen(true);
  };

  const onMenuClose = () => {
    setMenuIsOpen(false);
  };

  const DropdownIndicator = (props) =>
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <ArrowDropDownIcon />
      </components.DropdownIndicator>
    );

  return (
    <div className={clsx(styles.wrapper, isDisabled && styles.disabled)}>
      {label && <label className={styles.label}>{label}</label>}
      <div className={styles.field}>
        <AsyncPaginateBase
          debounceTimeout={300}
          components={{ DropdownIndicator }}
          classNamePrefix="react-select"
          className={[
            "react-select__control",
            "react-select__menu",
            "react-select__option",
            "react-select__option--is-focused",
            "react-select__single-value",
            "css-b8ldur-Input",
          ]}
          inputValue={value}
          placeholder={record}
          onInputChange={(value) => {
            resetPage();
            setInputValue(value);
          }}
          onChange={(value) => onItemSelected(value.id)}
          isDisabled={props.disabled}
          menuIsOpen={menuIsOpen}
          onMenuOpen={onMenuOpen}
          onMenuClose={onMenuClose}
          loadOptions={() => {
            handleChangePage(page);
            return loadPaginatedOptions();
          }}
          {...props}
        />
      </div>
    </div>
  );
};

export default AsyncSelect;
