import gql from "graphql-tag";

import { pagination } from "api/props/pagination";

export const tripsList = gql`
query Trips($search: String $pagination: PaginationParams $filter: FilterTrip) {
  trips(search_value: $search pagination: $pagination filter: $filter) {
    entities {
      distance
      startTimestamp
      stopTimestamp
      env
      fetched
      id
      status
    }
    pagination{
      ${pagination}
    }
  }
}
`;

export const TaskTripsQuery = gql`
  query Trips($search: String $pagination: PaginationParams $filter: FilterTrip) {
    trips(search_value: $search pagination: $pagination filter: $filter) {
      entities {
        id
        status
        startTimestamp
        distance
        stopTimestamp
        env
      }
      pagination{
          ${pagination}
        }
    }
  }
`;

export const importTripsQuery = gql`
  query xgTrips(
    $env: XgEnv!
    $deviceId: ID
    $tripId: UUID
    $pagination: XgPaginationParams
  ) {
    xgTrips(
      env: $env
      deviceId: $deviceId
      tripId: $tripId
      pagination: $pagination
    ) {
      entities {
        id
        status
        distance
        startTime
        endTime
      }
      pagination {
        totalEntries
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
`;
