import React from "react";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { SnackbarProvider } from "notistack";

import MainPage from "./routes";

const theme = createMuiTheme({
  typography: {
    fontFamily: "Roboto Mono",
    fontSize: 14,
  },
  palette: {
    type: "dark",
  },
  button: {
    variant: "contained",
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={5}>
        <MainPage />
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
