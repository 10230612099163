import { ENVIRONMENTS } from "consts";

export const ENVIRONMENTS_OPTIONS = [
  { value: "ALL", text: "All" },
  ...ENVIRONMENTS,
];

export const FETCHED_OPTIONS = [
  { value: "ALL", text: "All" },
  { value: true, text: "Fetched" },
  { value: false, text: "Non-Fetched" },
];

export const HEADER_ITEMS = [
  "Trip ID",
  "Environment",
  "Status",
  "Fetched",
  "Start Time",
  "Stop Time",
  "Distance",
  "Duration",
];

export const FIELDS_ITEMS = [
  "id",
  "env",
  "status",
  "fetched",
  "startTimestamp",
  "stopTimestamp",
  "distance",
  "duration",
];
