export const MESSAGES = {
  SELECT_ITEM: "Please select",
  INCORRECT_VALUE: "Incorrect value",
  REQUIRED: "Required",
  MIN_GREATER_THAN_MAX: "The minimum value cannot be greater than the maximum",
  SET: "Set",
  EDITED: "edited",
  MAX_ITERATION_VALUE: "Max value is 30",
};

export const REGEXP = {
  TEXTFIELD: /^[\w][\w '-]*$/,
  RICH_TEXTFIELD: /^[\w([№#][\w ',.?!:;\-№#()[\]]*$/,
  ZIP: /(^\d{5}$)|(^\d{5}-\d{4}$)/,
  POSITIVE_INTEGER_NUMBERS_WITHOUT_0: /^\+?([1-9]\d*)$/,
};

export const ENVIRONMENTS = [
  { value: "DEV", text: "Develop" },
  { value: "READYWIRELESS", text: "Readywireless" },
  { value: "PLATFORM", text: "Platform" },
];

export const NOTIFICATION_TYPES = {
  ERROR: "error",
  WARNING: "warning",
  SUCCESS: "success",
};

export const STEPS_TYPES = {
  LOOP: "LOOP",
  RANDOM_DELAY: "RANDOM_DELAY",
  DELAY: "DELAY",
  TRIP: "TRIP",
};

export const TASKS_ACTIONS = [
  STEPS_TYPES.DELAY,
  STEPS_TYPES.RANDOM_DELAY,
  STEPS_TYPES.LOOP,
  STEPS_TYPES.TRIP,
];

export const TASK_STATUSES = {
  RUNNING: "RUNNING",
  PAUSED: "PAUSED",
  NOT_RUNNING: "NOT_RUNNING",
};

export const TASK_STATUSES_ARRAY = [
  TASK_STATUSES.RUNNING,
  TASK_STATUSES.PAUSED,
  TASK_STATUSES.NOT_RUNNING,
];

export const ROUTES = {
  TASKS: "platform/tasks",
  TRIPS: "platform/trips",
  EDIT_TRIPS: "edit_trips",
  EDIT_DEVICES: "edit_devices",
  TASK_ID: "taskID",
  TRIP_ID: "tripID",
};

export const TRIPS_STATUSES_ARRAY = [
  "DECLINED",
  "IMPOSSIBLE",
  "NORMAL",
  "SHORT",
  "SLOW",
  "UNUSUAL",
];

export const TRIPS_SUMILATED_SATUSES = {
  NOT_SIMULATED: "NOT_SIMULATED",
  RUNNING: "RUNNING",
  SIMULATED: "SIMULATED",
};

export const DATE_FORMATS = {
  FULL_DATE: "DD-MM-YYYY hh:mm:ss",
};
