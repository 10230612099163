import React from "react";
import clsx from "clsx";
import { TextField as MUTextField, Typography } from "@material-ui/core";

import styles from "./styles.module.scss";

const TextField = ({
  size = "small",
  multiline,
  label,
  variant = "filled",
  type = "search",
  fullWidth,
  isError,
  id,
  register,
  name,
  errorMessage,
  errors,
  onChange,
  placeholder,
  classNames,
  isDisabled,
}) => {
  return (
    <div className={clsx(classNames, styles.wrapper)}>
      <Typography className={styles.label} gutterBottom>
        {label}
      </Typography>
      <MUTextField
        error={isError}
        inputRef={register}
        name={name}
        size={size}
        multiline={multiline}
        variant={variant}
        type={type}
        fullWidth={fullWidth}
        id={id}
        onChange={onChange}
        placeholder={placeholder}
        disabled={isDisabled}
      />
      <div className={styles.errorMessage}>
        {errors && errors[name] && errorMessage}
      </div>
    </div>
  );
};

export default TextField;
