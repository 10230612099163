import React, { useState, useEffect } from "react";
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { Switch, Route, useHistory } from "react-router-dom";
import gql from "graphql-tag";
import { withApollo } from "react-apollo";

const deviceListQuery = gql(`
  query Devices($search: String $pagination: PaginationParams) {
    devices(search_value: $search pagination: $pagination) {
      entities {
        id
        clientId
        description
        name
      }
      pagination{
        page
        perPage
        totalEntries
      }
    }
  }
`);

function DeviceList({ client }) {
  const [search, setSearch] = useState("");
  const [devices, setDevices] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    perPage: 50,
    totalEntries: 0,
  });
  const history = useHistory();

  const fetchDevices = () => {
    const paginationParams = {
      page: pagination.page,
      perPage: pagination.perPage,
    };

    client
      .query({
        query: deviceListQuery,
        variables: { search, pagination: paginationParams },
      })
      .then(({ data }) => {
        setDevices(data.devices.entities);
        setPagination({
          ...data.devices.pagination,
          page: data.devices.pagination.page,
        });
      })
      .catch(() => {});
  };

  // eslint-disable-next-line
  useEffect(() => fetchDevices(), [search, pagination.page]);

  const handleChangePage = (_event, page) => {
    setPagination({ ...pagination, page: page + 1 });
  };

  const changeSearch = (event) => {
    setSearch(event.target.value);
  };
  const selectDevice = (event, deviceID) => {
    history.push(`${history.location.pathname}/${deviceID}`);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h3">Devices</Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          size="small"
          label="Device ID"
          variant="outlined"
          type="search"
          value={search}
          onChange={changeSearch}
          fullWidth
        />
        <Paper>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Device ID</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Client Id</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {devices.map((device) => (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={device.id}
                    onClick={(event) => selectDevice(event, device.id)}
                  >
                    <TableCell>{device.id}</TableCell>
                    <TableCell>{device.name}</TableCell>
                    <TableCell>{device.description}</TableCell>
                    <TableCell>{device.clientId}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={pagination.totalEntries}
            rowsPerPage={pagination.perPage}
            page={pagination.page - 1}
            onChangePage={handleChangePage}
          />
        </Paper>
      </Grid>
      <Grid item xs={9}>
        <Switch>
          <Route path="/devices/:deviceID" />
        </Switch>
      </Grid>
    </Grid>
  );
}

export default withApollo(DeviceList);
