import React from "react";
import { TextField } from "@material-ui/core";

import styles from "./styles.module.scss";

const Search = ({
  size = "small",
  label,
  variant = "outlined",
  type = "search",
  search,
  onChange,
  fullWidth,
  placeholder,
}) => (
  <div className={styles.container}>
    <TextField
      size={size}
      label={placeholder}
      variant={variant}
      type={type}
      value={search}
      onChange={onChange}
      fullWidth={fullWidth}
    />
  </div>
);

export default Search;
